import { handleDatatableRedirect } from './utils';

require('multiple-select');
var orgTable;
$(document).on('turbolinks:load', function() {
  const createTableQueryData = function() {
    return {
      "save_state": true,
      "status_search": $('#admin_organization_status').val(),
      "state_search": $("#admin_organization_state").val(),
      "type_search": $("#admin_organization_type").val(),
      "platform_providers_search": $("#admin_organization_platform_provider").val(),
      "application_date": {
        "to": $("#admin_organization_application_date_to").val(),
        "from": $("#admin_organization_application_date_from").val(),
        "notSet": $("#application_date").prop('checked'),
      },
      "agreement_signed_date": {
        "to":  $("#admin_organization_agreement_signed_date_to").val(),
        "from": $("#admin_organization_agreement_signed_date_from").val(),
        "notSet": $("#agreement_signed_date").prop('checked'),
      },
      "expiration_date": {
        "to": $("#admin_organization_expiration_date_to").val(),
        "from": $("#admin_organization_expiration_date_from").val(),
        "notSet":  $("#expiration_date").prop('checked')
      },
      "search": $("#admin_organization_search").val(),
    };
  }

  orgTable = $('#organizations').DataTable( {
    serverSide: true,
    processing: true,
    searching: false,
    stateSave: true,
    columnDefs: [ {
      "targets": [7],
      "orderable": false
      ,
    },
    {
      "targets": [0],
      "width": "25%"
    },
    {
      "targets": [1],
      "width": "10%"
    },
    {
      "targets": [6],
      "width": "15%"
    }
    ],
    ajax: {
      "url": "/admin/organization_data.json",
      "type": "POST",
      "data": function ( d ) {
        return $.extend( {}, d, createTableQueryData());
      },
      "error": function( e ) {
        handleDatatableRedirect(e);
      }
    }
  });
  // Filter values
  $("#filter-button").on('click', function(){
    orgTable.ajax.reload();
  });

  // Reset all of the fields to their default value.
  $("#reset-button").on('click', function(){
    $("#admin_organization_status").val("");
    $("#admin_organization_state").val("");
    $("#admin_organization_type").val("");
    $("#admin_organization_platform_provider").val("");
    $("#admin_organization_application_date_to").val("");
    $("#admin_organization_application_date_from").val("");
    $("#admin_organization_agreement_signed_date_to").val("");
    $("#admin_organization_agreement_signed_date_from").val("");
    $("#admin_organization_expiration_date_to").val("");
    $("#admin_organization_expiration_date_from").val("");
    $("#admin_organization_search").val("");
    $("#expiration_date").prop('checked', false);
    $("#agreement_signed_date").prop('checked', false);
    $("#application_date").prop('checked', false);
    $("#application_date").val("");
    $("#admin_organization_type").multipleSelect('uncheckAll');
    $("#admin_organization_platform_provider").multipleSelect('uncheckAll');
    orgTable.ajax.reload();
  });


  // Prompt a download by creating a Blob with the file contents received by Ajax, creating a URL for it, and using the download attribute
  const saveData = (function () {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (fileName, data) {
      const blob = new Blob([data], {type: "octet/stream"}),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    };
  }());

  $("#download-button-organizations").on('click', function (e) {
    $.ajax({
      type: "GET",
      url: "/admin/organizations.csv",
      contentType: 'text/csv',
      data: createTableQueryData(),
      success: function (csv) {
        orgTable.ajax.reload();
        saveData('organizations-report-' + new Date().toISOString().slice(0, 10) + '.csv', csv);
      }
    });
  });
});

var platformProviderCheckbox = function() {
  var toggle = function(){
    if($('#organization_other-checkbox').is(':checked')) {
      // Make the textfield editable.
      $("#other_platform_provider").prop( "disabled", false );
    } else{
      // unchecked, remove the value from the textfield so the user doesn't have to.
      $('#other_platform_provider').val("");
      $("#other_platform_provider").prop( "disabled", true );
      $("#other_platform_provider").removeClass("error");
      $("#other_platform_provider-error").remove();
    }
  }

  // Page load.
  toggle();

  $('#organization_other-checkbox').on('change', function(){
    // On checkbox change, toggle the field.
    toggle();
  });
}

$(document).on('turbolinks:load', platformProviderCheckbox);
$(document).on("turbolinks:before-cache", function() {
  if ($('#organizations').length === 1) {
    return orgTable.destroy();
  }
});
