
// Turbolinks does not know how to save the state of a dropdown
// So we will help them, by storing the state in a hidden field
// This is since JS cloneNode does not clone the selected index.

var getHiddenField = (element) => $("#"+element.id.replace("_index", ""));
// On load populate the selects with their hidden field counter-part.
var addHiddenFields = function(){
    // On load find hidden fields
    $(".persit-hidden").each(function(index, element)
    {
        // Grab their corresponding select fields
        if(getHiddenField(element)[0].multiple)
        {
            if(element.value !== ''){
                var array = element.value.split(",").map((number)=> parseInt(number));
                getHiddenField(element).multipleSelect('setSelects', array);
            }
        }
        else{
            getHiddenField(element).val( element.value );
        }
        
    });
    // On change of any of our selects make sure they get saved into their hidden field. 
    // Turbolinks will take care of the rest.
    $(".persit-select").on('change', function(element){
        var id = (element.target.id + "_index");
        if(!element.target.multiple)
        {
            $("#"+id).val(element.target.value);
        }
        else
        {
            var selects = $("#"+element.target.id).multipleSelect('getSelects');
            $("#"+id).val(selects);
        }
    });
}

var destroyMultiSelectTags = function(){
    $(".multiple-select").each(function(index, element){
        if(element.id)
        {
            $("#"+element.id).multipleSelect('destroy');
        }
    });
}

$(document).on('turbolinks:load', addHiddenFields);
$(document).on('turbolinks:before-cache', destroyMultiSelectTags);
