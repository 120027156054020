$(document).on('turbolinks:request-start', function(event) {
    var xhr = event.originalEvent.data.xhr;
    xhr.setRequestHeader("X-Turbolinks-Nonce", $("meta[name='csp-nonce']").prop('content'));
  });
  
$(document).on('turbolinks:before-cache', function() {
$('script[nonce]').each(function(index, element) {
    $(element).attr('nonce', element.nonce)
})
});
