var CURRENT_STEP = 0;
var MIN_STEP = 0;
var MAX_STEP = 6;
var form_numberS = new Set([1, 2, 3, 4]);
var isRequired = ' is a required field.';
var captchaResponse;
var termsAgreed;
const personRules = {
  'first_name': {
    required: true,
  },
  'last_name': {
    required: true,
  },
  'email': {
    required: true
  },
  'phone_number': {
    required: true
  },
  'title':{
    required: true
  },
};


var enableSubmitButton = function(){
  $('.btn-submit-application').prop('disabled', (captchaResponse && termsAgreed) || $("#test_env").val() === 'true' && termsAgreed ? false : 'disabled');
}

var recaptchaCallback = function(response) {
  //Captcha Response
  captchaResponse = response;
  enableSubmitButton();
};

function submitForm() {
  // Since turbolinks never reloads we need to disable button after submission.
  $('.btn-submit-application').prop('disabled', true);
  // Mock up the needed form object
  var formObject = {
    'organization':
    {
      'applicant_attributes': {},
      'dean_attributes': {},
      'signator_attributes': {},
      'edtpa_coordinator_attributes': {},
      'platform_provider_ids': []
    }
  };

  // Loop through each element
  $('form[id^="form_number_"] input,form[id^="form_number_"] select').each((idx, el) => {
    var formName = $('#'+el.form.id).attr('name');
    if (el.type === 'checkbox') {
      if (el.checked && formName === 'organization_information') {
        formObject['organization']['platform_provider_ids'].push(el.value);
      }
    } else {
      // Any fields from organization_information is not nested.
      if(formName == 'organization_information') {
        formObject['organization'][el.name] = el.value; 
      }
      // Nested fields should be put with their form and not in the parent.
      else {
        formObject['organization'][formName][el.name] = el.value; 
      }       
    }
  });

  // Add the captcha response and agreement signed here.
  formObject['organization']['captcha_response'] = captchaResponse;
  formObject['organization']['agreement_signed'] = termsAgreed;
  $.ajax({
    method: 'POST',
    url: '/join',
    contentType: 'application/json',
    data: JSON.stringify(formObject)
  }).fail(function(error){
    goToStep(5);
  });
  goToStep(5);
}

function goToStep(step) {
  // Subtract one, so that when we change step, we increment inside that method
  CURRENT_STEP = step - 1;
  changeStep(1);
}

function changeStep(step) {
  // Do not let the step go out of bounds
  CURRENT_STEP = step < 0 ? Math.max(CURRENT_STEP + step, MIN_STEP) : Math.min(CURRENT_STEP + step, MAX_STEP);

  $('div[id^="step-"]').hide();
  $(`div#step-${CURRENT_STEP}`).show().focus();
  window.scrollTo(0,0);

  // Show Step Navigation on left
  if (form_numberS.has(CURRENT_STEP)) {
    $('ul.step-navigation').show();
    $('.form-wizard-actions').show();
  } else {
    $('ul.step-navigation').hide();
    $('.form-wizard-actions').hide();
  }

  // All previous steps along with current should be fully opaque, whereas all future steps are not
  $('li[id^="li-step-"]').each((idx, el) => {
    var step = el.attributes['data-step'].value;

    if (parseInt(step) <= CURRENT_STEP) {
      el.classList.remove('opacity-30');
    } else {
      el.classList.add('opacity-30');
    }

    // Add checkmarks to previously filled in steps
    if (parseInt(step) < CURRENT_STEP) {
      el.firstElementChild.innerHTML = '✔';
    } else {
      el.firstElementChild.innerHTML = '';
    }
  });

  toggleActionButton();
}

function isStepValid(step) {
  var result = true;

  $(`div#step-${step} .validate-form`).each(function(_, element) {
    result &= $(element).valid();
  });

  // Add an error message to the sr-errors div for screen readers
  if (!result) {
    var inputErrors = $('input.error');
    $('#sr-errors').text(`Validation Errors: ${inputErrors.length}`).attr('role', 'alert').focus();
  } else {
    $('#sr-errors').text('').removeAttr('role');
  }

  return result; 
}

function toggleActionButton() {
  // If second to last step, we replace continue button with submit application
  // and apply rules associated with submitting
  if (CURRENT_STEP === MAX_STEP - 2) {
    $('.btn-submit-application').show();
    $('.btn-next-step').hide();
  } else {
    $('.btn-submit-application').hide();
    $('.btn-next-step').show();
  }
}

function setupFormStep1() {
  // We split form_number_1 into two separate steps.
  $('#form_number_1').validate(
    {
      errorClass: 'is-invalid error',
      rules: {
        'first_name': {
          required: true,
        },
        'last_name': {
          required: true,
        },
        'email': {
          required: true
        },
        'email_verify': {
          equalTo: '#applicant_email',
          required: true
        },
        'title':{
          required: true
        },
        'organization_url':{
          required: true
        }
      },
      messages: {
        'email_verify': {
          equalTo: 'Please ensure this matches the Applicant Email Address.',
          required: 'Email Verification' + isRequired
        },'first_name': {
          required: 'Applicant First Name' + isRequired,
         },
         'last_name': {
          required: 'Applicant Last Name' + isRequired,
         },
         'email': {
          required: 'Applicant Email Address' + isRequired
         },
         'title':{
           required: 'Applicant Title' + isRequired
         },
         'name':{
           required: 'EPP/Organization Name' + isRequired
         },
         'city': {
           required: 'City' + isRequired
         },
         'state':{
           required: 'State' + isRequired
         },
         'zip_code':{
           required: 'ZIP Code' + isRequired
         }
      }
    }
  );
  $('#form_number_2').validate(
    {
      errorClass: 'is-invalid error',
      rules: {
        'name':{
          required: true
        },
        'address_line_one': {
          required: true
        },
        'city': {
          required: true
        },
        'state':{
          required: true
        },
        'zip_code':{
          required: true
        },
         'url':{
           required: true
         }
      },
      messages: {
        'name':{
          required: 'EPP/Organization Name' + isRequired
        },
        'address_line_one': {
          required: 'Street Address' + isRequired
        },
        'city': {
          required: 'City' + isRequired
        },
        'state':{
          required: 'State' + isRequired
        },
        'zip_code':{
          required: 'ZIP Code' + isRequired
        },
        'url':{
          required: 'URL of EPP/Organization' + isRequired
        }
      }
    }
  );
}

function setupFormStep2() {
  $('#form_number_3').validate(
    {
      errorClass: 'is-invalid error',
      rules: {
        ...personRules,
        'dean_email_verify': {
          equalTo: '#dean_email',
          required: true
        }
      },
      messages: {
        'first_name': {
          required: 'Dean/Director First Name' + isRequired,
        },
        'last_name': {
          required: 'Dean/Director Last Name' + isRequired,
        },
        'email': {
          required: 'Dean/Director Email' + isRequired
        },
        'dean_email_verify': {
          equalTo: 'Please ensure this matches the Dean/Director Email Address.',
          required: 'Email Verification' + isRequired
        },
        'title':{
          required: 'Dean/Director Title' + isRequired
        },
        'phone_number':{
          required: 'Dean/Director Phone Number' + isRequired
        }
      }
    }
  );
  $('#form_number_4').validate(
    {
      errorClass: 'is-invalid error',
      rules: {
        ...personRules,
        'email_verify': {
          equalTo: '#signator_email',
          required: true
        },
      },
      messages: {
        'first_name': {
          required: 'Authorized Signator First Name' + isRequired,
        },
        'last_name': {
          required: 'Authorized Signator Last Name' + isRequired,
        },
        'email': {
          required: 'Authorized Signator Email' + isRequired
        },
        'email_verify': {
          equalTo: 'Please ensure this matches the Authorized Signator Email Address.',
          required: 'Email Verification' + isRequired
        },
        'title':{
          required: 'Authorized Signator Title' + isRequired
        },
        'phone_number':{
          required: 'Authorized Signator Phone Number' + isRequired
        }
      }
    }
  );
}

function setupFormStep3() {
  $('#form_number_5').validate(
    {
      errorClass: 'is-invalid error',
      rules: {
        ...personRules,
        'coordinator_email_verify': {
          equalTo: '#coordinator_email',
          required: true
        },
      },
      messages: {
        'first_name': {
          required: 'edTPA Coordinator/edTPA Primary POC First Name' + isRequired,
        },
        'last_name': {
          required: 'edTPA Coordinator/edTPA Primary POC Last Name' + isRequired,
        },
        'email': {
          required: 'edTPA Coordinator/edTPA Primary POC Email' + isRequired
        },
        'coordinator_email_verify': {
          equalTo: 'Please ensure this matches the edTPA Coordinator/edTPA Primary POC Email Address.',
          required: 'Email Verification' + isRequired
        },
        'title':{
          required: 'edTPA Coordinator/edTPA Primary POC Title' + isRequired
        },
        'phone_number':{
          required: 'edTPA Coordinator/edTPA Primary POC Phone Number' + isRequired
        }
      }
    }
  );
  $('#form_number_6').validate(
    {
      rules: {
        'platform_provider_ids[]': {
          required: true,
          minlength: 1
        },
        'other_platform_provider':{
          required: function(element){
            return !$("#other_platform_provider").prop("disabled");
          },
        }
      },
      messages: {
        'platform_provider_ids[]': alertifyMessage("Please select at least one item below"),
        'other_platform_provider': "Other cannot be blank."
      },
      errorPlacement: function(error, element) {
        if(element[0].id === "other_platform_provider"){
          error.insertAfter('#other_platform_provider')
        }
        else{
          // Special error placement for checkboxes. Without this, errors would propagate below the first checkbox.
          error.insertAfter('.platform-providers');
        }
        
      }
    }
  );
}

function alertifyMessage(message) {
  return '<div class="alert alert-danger">' + message + ' <i class="bi bi-exclamation-circle"></i></div>'
}

function setupButtons() {
  // Initial screen, move step forward and set account type
  $('.membership-card-grid button[id^="btn-"]').on('click', (e) => {
    e.preventDefault();

    var currentTarget = e.currentTarget;
    var organizationTypeID = currentTarget.attributes['data-organization-type-id'].value;
    var organizationTypeTitle = currentTarget.attributes['data-title'].value;
    var title = $('#step-1 .form-wizard-header-title, #step-2 .form-wizard-header-title, #step-3 .form-wizard-header-title').html();
    if(title != null){
     title = title.split('-')[0]
    }
    title = title + ` - ${organizationTypeTitle}`;
    $('#step-1 .form-wizard-header-title, #step-2 .form-wizard-header-title, #step-3 .form-wizard-header-title').html(title);
    $('#organization_type_id').val(organizationTypeID);

    changeStep(1);
  });

  // Step Navigation on left - don't move forward unless previous steps are valid
  $('li[id^="li-step-"]').on('click', (e) => {
    var step = e.currentTarget.attributes['data-step'].value;

    if (isStepValid(parseInt(step) - 1) && isStepValid(parseInt(step) - 2)) {
      goToStep(parseInt(step));
    }
  });

  $('#btn-previous-step').on('click', (e) => {
    e.preventDefault();
    changeStep(-1);
  });

  $('#btn-popup-cancel-modal').on('click', function(e){
    e.preventDefault();
    $('#cancel-application-modal').show().trigger('shown');
  });

  $('#cancel-application-modal').on('shown', function(e) {
    $('#cancel-modal-title').attr('tabindex', -1).focus();
  });

  $('#btn-finish-application').on('click', function(e){
    e.preventDefault();
    $('#cancel-application-modal').hide();
  });

  $('#btn-cancel-application').on('click', function(e){
    e.preventDefault();
    location.href = '/';
  });



  $('#btn-return-to-home').on('click', function(e) {
    e.preventDefault();
    location.href = '/';
  });

  $('.btn-next-step').on('click', function(e) {
    e.preventDefault();
    if (!isStepValid(CURRENT_STEP)) {
      return;
    }

    if (CURRENT_STEP !== MAX_STEP) {
      changeStep(1);
    }
  });

  $('#agree_to_terms_of_usage').on('click', function(e){
    termsAgreed = e.target.checked;
    enableSubmitButton();
  });
}

var setUpFrom = function(){
  // Reset Current Step
  CURRENT_STEP = 0;

  setupFormStep1();
  setupFormStep2();
  setupFormStep3();

  setupButtons();
}

$(document).on('turbolinks:load', setUpFrom);
$(document).on('turbolinks:load', enableSubmitButton);
$(document).on('turbolinks:load', function(){
  $('.btn-submit-application').on('click', function(e){
    e.preventDefault();
    submitForm();
  });
});
window.recaptchaCallback= recaptchaCallback
