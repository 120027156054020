// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


//Imports
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "bootstrap";
import 'bootstrap-icons/font/bootstrap-icons.css';
import "./users";
import "./platformProviders";
import "./announcements";
import "./faqs";
import "./resources";
import "./resourceItems";
import "./organizations";
import "./tinyMce";
import "./resourceLibrary"
import "../stylesheets/application";
import "./membershipFormWizard";
import "./selectTags";
import "./home";
import "./backButton";
import "./turbolinksRequest";
import "./policy";
import "@fortawesome/fontawesome-free/css/all";
import "@fontsource/source-sans-pro/latin.css";
import "@fontsource/source-sans-pro/latin-ext.css";

// Turbolinks will crash if we return a 404 status
// When it crashes it will try to pick itself up by calling Rails.start() AGAIN.
// We can't have this called twice.
// Turbolinks will NOT be fixing this issue, they recommend either monkey patching their code
// OR something like this where if this error occurs we refresh the page for the user and move on.
try{
    Rails.start()
    Turbolinks.start()
    ActiveStorage.start()
}catch{
    location.reload();
}
    


require('jquery');
require('jquery-validation');
require("@nathanvda/cocoon");
require( 'datatables.net-bs4' );
require( 'datatables.net-responsive-bs4' );
// Require the use of images in webpacker.
require.context('../images', true, /\.(gif|jpg|png|svg)$/i);
