import { handleDatatableRedirect } from './utils';

var resourceItemsTable;
$(document).on('turbolinks:load', function(){
  resourceItemsTable = $('#resource_items').DataTable( {
    serverSide: true,
    processing: true,
    searching: false,
    columnDefs: [ {
      "targets": [8],
      "orderable": false
    }],
    ajax: {
      "url": "/admin/resource_items_data.json",
      "type": "POST",
      "data": function ( d ) {
        return $.extend( {}, d, {
          "save_state": "true",
          "status_search": $('#admin_resource_items_status').val(),
          "state_search": $("#admin_resource_items_state").val(),
          "type_search": $("#admin_resource_items_item_type").val(),
          "published_date": 
          {
            "to": $("#admin_resource_items_published_date_to").val(),
            "from": $("#admin_resource_items_published_date_from").val()
          },
          "end_date":
          {
            "to":  $("#admin_resource_items_end_date_to").val(),
            "from": $("#admin_resource_items_end_date_from").val()
          },
          "search": $("#admin_resource_items_search").val(),
        });
      },
      "error": function( e ) {
        handleDatatableRedirect(e);
      }
    }
  });
  // Filter values
  $("#filter-button").on('click', function(){
    resourceItemsTable.ajax.reload();
  });

  // Reset all of the fields to their default value.
  $("#reset-button").on('click', function(){
    $("#admin_resource_items_status").val("");
    $("#admin_resource_items_search").val("");
    $("#admin_resource_items_published_date_to").val("");
    $("#admin_resource_items_published_date_from").val("");
    $("#admin_resource_items_end_date_to").val("");
    $("#admin_resource_items_end_date_from").val("");
    $("#admin_resource_items_item_type").multipleSelect('uncheckAll');
    resourceItemsTable.ajax.reload();
  });

});

$(document).on("turbolinks:before-cache", function() {
  if ($('#resource_items_wrapper').length === 1) {
    return resourceItemsTable.destroy();
  }
});  

$(document).on('turbolinks:load', function() {
  $('.expandable').on('click', function(elem) {
    $(elem.currentTarget).toggleClass('truncate');
  });

  $('.resource-item-expand-links').on('click', function(elem) {
    var dataItem = $(elem.currentTarget).data('item');
    $(`.resource-item-link[data-item="${dataItem}"]`).toggleClass('d-none');
  });
});
