import { handleDatatableRedirect } from './utils';

var table;
$(document).on('turbolinks:load', function() {
  const createTableQueryData = function() {
    return {
      "save_state": "true",
      "status": $('#admin_users_status').val(),
      "role": $("#admin_users_role").val(),
      "state": $("#admin_users_state").val(),
      "organization_types": $("#admin_users_organization_type").val(),
      "last_login_date":
          {
            "from": $("#admin_users_last_login_date_from").val(),
            "to": $("#admin_users_last_login_date_to").val(),
            "never_logged": $("#admin_users_never_logged_in").is(':checked'),
          },
      "search": $("#admin_users_search").val(),
    };
  }

   table = $('#users').DataTable({
    serverSide: true,
    processing: true,
    searching: false,
    stateSave: true,
    "ajax": {
      "url": "/admin/user_data.json",
      "type": "POST",
      "data": function (d) {
        return $.extend({}, d, createTableQueryData());
      },
      "error": function( e ) {
        handleDatatableRedirect(e);
      }
    }
  });

  // Filter values
  $("#filter-button").on('click', function () {
    table.ajax.reload();
  });

  // Reset all of the fields to their default value.
  $("#reset-button").on('click', function () {
    $("#admin_users_status").val($("#status option:first").val());
    $("#admin_users_role").val("");
    $("#admin_users_state").val("");
    $("#admin_users_last_login_date_to").val("");
    $("#admin_users_last_login_date_from").val("");
    $("#admin_users_never_logged_in").prop('checked', false);
    $("#admin_users_search").val("");
    $("#admin_users_organization_type").val("");
    $("#admin_users_organization_type").multipleSelect('uncheckAll');
    table.ajax.reload();
  });

  // Prompt a download by creating a Blob with the file contents received by Ajax, creating a URL for it, and using the download attribute
  const saveData = (function () {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (fileName, data) {
      const blob = new Blob([data], {type: "octet/stream"}),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    };
  }());

  $("#download-button").on('click', function (e) {
    table.ajax.reload();
    $.ajax({
      type: "GET",
      url: "/admin/users.csv",
      contentType: 'text/csv',
      data: createTableQueryData(),
      success: function (csv) {
        saveData('accounts-report-' + new Date().toISOString().slice(0, 10) + '.csv', csv);
      }
    });
  });

});

var showPassword = function(){
  $(".icon-inside").on('click', function(event) {
    var target = event.currentTarget;
    var id = $(`#${target.id}`).data("target");
    var input = $(`#${id}`);

    if (input.attr("type") == "password") {
      $(`#${target.id} i`)[0].className = "bi bi-eye-slash-fill";
      input.attr("type", "text");
    } else {
      $(`#${target.id} i`)[0].className = "bi bi-eye-fill";
      input.attr("type", "password");
    }
  });
}


var adminEmailField =  function() {
  $("#change-email").on('click', function () {
    $("#email-field").toggle();
    $("#user-email").toggle();
  });

}

var myAccountsTable;
$(document).on('turbolinks:load', function(){
  const createTableQueryData = function() {
    return {
      "save_state": "true",
          "current_organization": $("#saved_organization").val(),
          "my_accounts": true,
          "status": $('#admin_my_accounts_my_status').val(),
          "role": $("#admin_my_accounts_role").val(),
          "last_login_date":
            {
              "from": $("#admin_my_accounts_last_login_date_from").val(),
              "to": $("#admin_my_accounts_last_login_date_to").val(),
              "never_logged": $("#admin_my_accounts_never_logged_in").is(':checked'),
            },
          "search": $("#admin_my_accounts_search").val(),
    };
  }
  var orgParam = $("#my-accounts").data("organization");
  var url = "/admin/my_accounts_data.json";
  if(orgParam)
  {
    url += "?organization_id="+orgParam;
  }

  myAccountsTable = $('#my-accounts').DataTable( {
    searching: false,
    serverSide: true,
    processing: true,
    stateSave: true,
    "ajax": {
      "url": url,
      "type": "POST",
      "data": function ( d ) {
        return $.extend( {}, d, createTableQueryData());
      }
    }
  });


  if($("#current_organization").val() !== $("#saved_organization").val())
  {
    $("#saved_organization").val($("#current_organization").val());
    myAccountsTable.ajax.reload();
  }

  // Filter values
  $("#filter-button").on('click', function(){
    myAccountsTable.ajax.reload();
  });

  // Reset all of the fields to their default value.
  $("#reset-button").on('click', function(){
    $("#admin_my_accounts_my_status").val($("#status option:first").val());
    $("#admin_my_accounts_role").val("");
    $("#admin_my_accounts_last_login_date_to").val("");
    $("#admin_my_accounts_last_login_date_from").val("");
    $("#admin_my_accounts_never_logged_in").prop('checked', false);
    $("#admin_my_accounts_search").val("");
    myAccountsTable.state.clear();
    myAccountsTable.ajax.reload();
  });

  // Prompt a download by creating a Blob with the file contents received by Ajax, creating a URL for it, and using the download attribute
  const saveData = (function () {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (fileName, data) {
      const blob = new Blob([data], {type: "octet/stream"}),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    };
  }());

  $("#download-button-my-accounts").on('click', function (e) {
    table.ajax.reload();
    var orgParam = $("#my-accounts").data("organization");
      var url = "/admin/my_accounts.csv";
      if(orgParam)
      {
        url += "?organization_id="+orgParam;
      }

    $.ajax({
      type: "GET",
      url: url,
      contentType: 'text/csv',
      data: createTableQueryData(),
      success: function (csv) {
        saveData('accounts-report-' + new Date().toISOString().slice(0, 10) + '.csv', csv);
      }
    });
  });

});

var toggleUsageAgreementSubmitButton = function(){
  $("#usage-agreement-checkbox").on('click', function(e){
    $("#usage-agreement-submit-btn").prop("disabled", !e.target.checked)
  });
}

$(document).on('turbolinks:load', toggleUsageAgreementSubmitButton);
$(document).on('turbolinks:load', showPassword);
$(document).on('turbolinks:load', adminEmailField);
$(document).on("turbolinks:before-cache", function() {
  if ($('#users').length === 1) {
    return table.destroy();
  }
  if ($('#my-accounts').length === 1) {
    return myAccountsTable.destroy();
  }
});



