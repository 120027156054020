var platformReportTable;
var platformProvider = function() {
  const createTableQueryData = function() {
    return {
     "save_state": true,
     "platform_provider_ids": $("#admin_platform_provider").val(),
     "type": $("#admin_organization_type").val(),
     "current_organization": $("#saved_organization").val(),
     "status": $("#admin_organization_status").val(),
     "state": $("#admin_organization_state").val(),
     "search": $("#admin_organization_search").val(),
     "platform_provider_date":{
       "to": $("#admin_organization_update_platform_provider_to").val(),
       "from": $("#admin_organization_update_platform_provider_from").val()
     } 
    };
  }
  platformReportTable = $('#platform_providers').DataTable( {
    serverSide: true,
    processing: true,
    searching: false,
    stateSave: true,
    columnDefs: [ {
      "targets": [2],
      "width": "10%"
    }],
    ajax: {
      "url": "/admin/platform_providers_data.json",
      "type": "POST",
      "data": function ( d ) {
        return $.extend( {}, d, createTableQueryData());
      }
    }
  });

  if($("#current_organization").val() !== $("#saved_organization").val())
  {
    $("#saved_organization").val($("#current_organization").val());
    platformReportTable.ajax.reload();
  }  

  $("#filter-button").on('click', () => {
    platformReportTable.ajax.reload();
  });

  $("#platform-reset-button").on('click', ()=> {
    $("#admin_organization_type").val("")
    $("#admin_organization_state").val("")
    $("#admin_organization_search").val("")
    $("#admin_organization_update_platform_provider_to").val("")
    $("#admin_organization_update_platform_provider_from").val("")
    $("#admin_organization_type").multipleSelect('uncheckAll');
    $("#admin_platform_provider").multipleSelect('uncheckAll');
    $("#admin_organization_status").multipleSelect('uncheckAll');
    platformReportTable.ajax.reload();
  });

  // Prompt a download by creating a Blob with the file contents received by Ajax, creating a URL for it, and using the download attribute
  const saveData = (function () {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (fileName, data) {
      const blob = new Blob([data], {type: "octet/stream"}),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    };
  }());

  $("#download-button-platform-providers").on('click', function (e) {
    $.ajax({
      type: "GET",
      url: "/admin/platform_report.csv",
      contentType: 'text/csv',
      data: createTableQueryData(),
      success: function (csv) {
        saveData('platform-providers-report-' + new Date().toISOString().slice(0, 10) + '.csv', csv);
      }
    });
  });
}




$(document).on("turbolinks:load", platformProvider);
$(document).on("turbolinks:before-cache", function() {
  if ($('#platform_providers').length === 1) {
    return platformReportTable.destroy();
  }
});