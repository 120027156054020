$(document).on('turbolinks:load', function(){
  // Display toasts
  $('.toast').toast('show');

  $('#search-resources').on('keypress', function(elem) {
    if (elem.which === 13) {
      console.log('SEARCHING...');
      // Do Something here
    }
  });
});

$(document).on('turbolinks:load', () => {
  $(".nav-link").on('click', (event) => {
    var selectedTarget = event.target.dataset.sr;
    $(".nav-link").each((_, element) =>{
      if(element.dataset.sr === selectedTarget)
      {
        $("#" + element.dataset.sr).html("Selected");
      }
      else{
        $("#" + element.dataset.sr).html("");
      }
    });
  });
});

$(document).on('turbolinks:load', function(){
  var hidden = true;
  $("#password_show").on('click', function(){
    var message;
    if(hidden)
    {
      message = "Password is visible.";
    }else{
      message = "Password is hidden.";
    }
    $("#sr-password-toggle").html(message);
    hidden = !hidden;
  });
});

$(document).on('turbolinks:load', () => {
  $("#skip-nav-btn").on('click', (event) =>{
    $("#main-content").focus();  
  });
});
