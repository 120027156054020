const tinymce = require('tinymce/tinymce');

/* Default icons are required for TinyMCE 5.3 or above. Also import custom icons if applicable */
require('tinymce/icons/default');

/* A editor theme (required) - customize the editor appearance by creating a 'skin' */
require('tinymce/themes/silver');

/* Import the editor skin - replace with a custom skin if applicable. */
require('tinymce/skins/ui/oxide/skin.css');

/* Import plugins - include the relevant plugin in the 'plugins' option. */
require('tinymce/plugins/advlist');
require('tinymce/plugins/code');
require('tinymce/plugins/emoticons');
require('tinymce/plugins/emoticons/js/emojis');
require('tinymce/plugins/link');
require('tinymce/plugins/lists');
require('tinymce/plugins/table');
require('tinymce/plugins/image');

/* Import content CSS */
const contentUiCss = require('tinymce/skins/ui/oxide/content.css');
const contentCss = require('tinymce/skins/content/default/content.css');

const settings = function(selector) {
  return {
    selector: selector,
    plugins: [],
    a_plugin_option: true,
    a_configuration_option: 400,
    skin: false,
    content_css: false,
    content_style: contentUiCss.toString() + '\n' + contentCss.toString(),
    plugins: ['code', 'link', 'lists'],
    color_map: [
      "8C1515", "Cardinal Red",
      "B83A4B", "Cardinal Red Light",
      "C0C0BF", "30% Black",
      "53565A", "Cool Grey",
      "175E54", "Palo Alto",
      "59B3A9", "Palo Verde Light",
      "A6B168", "Olive Light"
    ],
    toolbar: 'undo redo | styleselect | bold italic | forecolor backcolor | alignleft aligncenter alignright | outdent indent | bullist numlist | link | code',
    menubar: '',
    height: 400
  }
}

function image_handler (blobInfo, success, failure, progress) {
  var xhr, formData;
  xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  xhr.open('POST', '/admin/images');

  xhr.upload.onprogress = function (e) {
    progress(e.loaded / e.total * 100);
  };

  xhr.onload = function() {
    var json;

    if (xhr.status === 403) {
      failure('HTTP Error: ' + xhr.status, { remove: true });
      return;
    }

    if (xhr.status < 200 || xhr.status >= 300) {
      failure('HTTP Error: ' + xhr.status);
      return;
    }

    json = JSON.parse(xhr.responseText);

    if (!json || typeof json.location != 'string') {
      failure('Invalid JSON: ' + xhr.responseText);
      return;
    }
    // The return should also give us the slug of the image.
    var currentSlugs = $("#image-slugs").val();
    $("#image-slugs").val(currentSlugs + json.slug + ",");
    success(json.location);
  };

  xhr.onerror = function () {
    failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
  };

  formData = new FormData();
  formData.append('file', blobInfo.blob(), blobInfo.filename());
  token = $('meta[name=csrf-token]').attr('content');
  xhr.setRequestHeader('X-CSRF-Token', token);
  xhr.send(formData);
};

const announcementSettings = function(selector) {
  return {
    selector: selector,
    relative_urls : false,
    remove_script_host : true,
    document_base_url : "/",
    convert_urls : true,
    a_plugin_option: true,
    a_configuration_option: 400,
    skin: false,
    content_css: false,
    content_style: contentUiCss.toString() + '\n' + contentCss.toString(),
    plugins: ['code', 'link', 'lists', 'image'],
    images_upload_handler: image_handler,
    color_map: [
      "8C1515", "Cardinal Red",
      "B83A4B", "Cardinal Red Light",
      "C0C0BF", "30% Black",
      "53565A", "Cool Grey",
      "175E54", "Palo Alto",
      "59B3A9", "Palo Verde Light",
      "A6B168", "Olive Light"
    ],
    toolbar: 'undo redo | styleselect | bold italic | forecolor backcolor | alignleft aligncenter alignright | outdent indent | bullist numlist | link | code | image',
    menubar: '',
    height: 400
  }
}


const initTinyMce = function() {
  // Add CSRF Token to request for proper verification.
  tinymce.init(announcementSettings('#news'));
  tinymce.init(settings('textarea.editor'));
}

// Destroy the instance before turbolinks cache
// This way we don't have a problem reloading it on another page.
const destroyTinyMce = function() {
  tinymce.remove();
}

$(document).on('cocoon:after-insert', function() {
  tinymce.init(settings('textarea.editor'));
});
$(document).on('turbolinks:load', initTinyMce);
$(document).on('turbolinks:before-cache', destroyTinyMce);