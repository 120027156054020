// datatables don't handle redirect response out of the box
// this is for a corner case when a user gets logged out while
// on a datatable page and attempts to use a filter. We catch
// response error and if it has something to do with login,
// redirect to the appropriate location
const handleDatatableRedirect = (e) => {
  var location = e.getResponseHeader("location");
  if (location != null && location.includes("login")) {
        window.location.replace(location);
  }
}

export { handleDatatableRedirect };

