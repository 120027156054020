var faqTable;
var faq = function() {
  const createTableQueryData = function() {
    return {
      save_state: "true",
      status: $("#status").val(),
      faq_category: $("#faq_category").val(),
      published_date:
      {
        to: $("#published_date_to").val(),
        from: $("#published_date_from").val()
      },
      search: $("#search").val()
    };
  }
  
  faqTable = $('#faqs').DataTable( {
    serverSide: true,
    processing: true,
    searching: false,
    stateSave: true,
    columnDefs: [ {
      "targets": [1],
      "orderable": false
      ,
    }],
    ajax: {
      "url": $('#faqs').data("source"),
      "type": "POST",
      "data": function ( d ) {
        return $.extend( {}, d, createTableQueryData());
      }
    }
  });

  $("#filter-button").on('click', () => {
    faqTable.ajax.reload();
  });

  $("#reset-button").on('click', ()=> {
    $("#status").val("");
    $("#published_date_to").val("");
    $("#published_date_from").val("");
    $("#search").val("");
    $("#faq_category").val("");
    faqTable.ajax.reload();
  });
}




$(document).on("turbolinks:load", faq);
$(document).on("turbolinks:before-cache", function() {
  if ($('#faqs').length === 1) {
    return faqTable.destroy();
  }
});