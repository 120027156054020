/**
 * keyboard navigation with tabs.
 * arrow up or right => open next tab
 * arrow down or bottom => open previous tab
 **/


$(document).on('turbolinks:load', () => {
var tablist = $("[role='tablist']").find("[role='tab']");
tablist.on('keydown', function(){
  var index = tablist.index($(this));
  var numbTabs = tablist.length;
  var nextId;

  if(numbTabs>1){

    if(event.keyCode == 40 || event.keyCode == 39) { // DOWN or RIGHT
      nextId = tablist.eq(index+1);

      if(index == numbTabs-1){ // if it is the last not empty tab, then go to first not empty tab
        nextId = tablist.eq(0);
      }

      nextId.focus(); // focus on next tab
      nextId.click();
    }

    if(event.keyCode == 38 || event.keyCode == 37) { // UP or LEFT
      nextId = tablist.eq(index-1);

      if(index == 0){ // if it is the last not empty tab, then go to first not empty tab
        nextId = tablist.eq(numbTabs-1);
      }

      nextId.focus(); // focus on next tab
      nextId.click();
    }
  }
});
});

$(document).on('turbolinks:load', () => {
var tablist = $("[role='tablist']").find("[role='tab']");
tablist.on('click',function () {
  var thisTabId = $(this).attr('id');
  var thisTabList = $(this).parents("[role='tablist']").find("[role='tab']");
  thisTabList.each(function(){
    var thisTabPanel = $("[role='tabpanel'][id='"+$(this).attr('aria-controls')+"']");
    if($(this).attr('id') == thisTabId){
      thisTabPanel.removeClass("hide");
      $(this).attr('aria-selected', true)
      $(this).attr('tabindex', '0');
    } else {
      thisTabPanel.addClass("hide");
      $(this).attr('aria-selected', false)
      $(this).attr('tabindex', '-1');
    }
  });
});
});
$(document).on('turbolinks:load', () => {
$("[role='tablist'] [role='tab'][aria-selected='true']").click();
});



