import { handleDatatableRedirect } from './utils';


const ENTER_KEY = 13;
$(document).on('turbolinks:load', function() {
  $(".info-icon").on('click', function () {
    var itemId = $(this).data("item");
    $(`#${itemId}-description`).toggle();
  });
});

$(document).on('turbolinks:load', function() {
  var copyLink = $(".direct-link"); 
  copyLink.tooltip({animation: true});
  // Disable on load, we don't want to show this unless a user has clicked on it.
  copyLink.tooltip('disable');
  copyLink.on("click", function () {
    // Write to clipboard.
    navigator.clipboard.writeText($(this).data("link"));
    // Enable tooltip to show it
    $(this).tooltip('enable');
    $(this).tooltip('show');
    // Disable the tooltip after the user moves away from it.
    $(this).tooltip('disable');

  });

  copyLink.keypress(function(event){
    var keycode = (event.keyCode ? event.keyCode : event.which);
    if(keycode == '13'){
      // Write to clipboard.
    navigator.clipboard.writeText($(this).data("link"));
    // Enable tooltip to show it
    $(this).tooltip('enable');
    $(this).tooltip('show');
    // Disable the tooltip after the user moves away from it.
    $(this).tooltip('disable');
    }
  });
});

$(document).on('turbolinks:load', function() {
  $("#print-page").on('click', function(){
    window.print();
  });

  $("#print-page").keypress(function(event){
    var keycode = (event.keyCode ? event.keyCode : event.which);
    if(keycode == '13'){
      window.print();
    }
  });
});

var resourceTable;
$(document).on('turbolinks:load', function(){
  resourceTable = $('#resource_library').DataTable( {
    serverSide: true,
    processing: true,
    searching: false,
    stateSave: true,
    // Disable showing hidden columns on mobile
    responsive: {
      details: {
        display: function() {}
      }
    },
    pageLength: 15,
    language: {
      info: 'Viewing _START_ through _END_ of _TOTAL_',
      infoFiltered: ''
    },
    order: [],
    dom: '<"row"<"col-12 col-md-6 order-2 order-md-1"i><"col-12 col-md-6 order-3 order-md-2"p><"col-12 order-1 order-md-3"t>>',
    columnDefs: [
      // Allow for text truncation. In order to truncate text, the table cell needs to have a max-width set.
      // Because we're using a responsive plugin, we can't use explicit pixels, so we're grabbing the width
      // of the corresponding header to set the width of the cell.
      {
        targets: [0, 1],
        className: 'truncate expandable pointer',
        createdCell: function( cell, cellData, rowData, rowIndex, colIndex ) {
          var headerCells = $('table#resource_library tr th');
          if (headerCells) {
            var headerCell = headerCells[colIndex];
            var width = headerCell.style.width;
            cell.style['max-width'] = width;
          }
        }
      },
      {
        targets: 0,
        orderable: false,
        width: "30%",
        className: 'all'
      },
      {
        targets: 1,
        orderable: false,
        width: "50%",
        className: 'min-tablet-l'
      },
      {
        targets: 2,
        orderable: false,
        width: "10%",
        className: 'min-tablet-l'
      },
      {
        targets: 3,
        orderable: false,
        width: "10%",
        className: 'all'
      }
    ],
    ajax: {
      "url": "/resources_data.json",
      "type": "POST",
      "data": function ( d ) {
        var searchValue = $("#resource_search").val();
        return $.extend( {}, d, {
          "search": searchValue
        });
      },
      "error": function( e ) {
        handleDatatableRedirect(e);
      }
    }
  });

  $("#resource_library").on( 'draw.dt', function () {
    toggleAlertModal();
  });

  // When clicking on an expandable element, toggle the truncate class to expand or shrink
  resourceTable.on('click', '.expandable', function(elem) {
    $(elem.currentTarget).toggleClass('truncate');
  });

  $('#resource_search').on('keypress', function(elem) {
    if (elem.which === 13) {
      resourceTable.ajax.reload();
      $("#resource_library").focus();
    }
  });

  $('#clear-search').on('click', function(){
    $('#resource_search').val('');
    resourceTable.ajax.reload();
  });
});

$(document).on("turbolinks:before-cache", function() {
  if ($('#resource_library_wrapper').length === 1) {
    return resourceTable.destroy();
  }
}); 

var toggleAlertModal = function() {
  $('.btn-popup-restricted-resource').on('click', function(e){
    e.preventDefault();
    $('#restricted-alert-modal').show();
  });

  $('.btn-close-restricted-alert-modal').on('click', function(e){
    e.preventDefault();
    $('#restricted-alert-modal').hide();
  });
}


function equalItemHeight() {
  // Set each item to have the max height on change.
  var maxHeight = 0;
  $('.carousel').each(function(){
    var items = $('.carousel-item', this);
    var container = $('.carousel-content-container', this);
    items.css('min-height', 0);
    container.css('min-height', 0);

    var maxItemHeight = Math.max.apply(null,
        items.map(function(){
            return $(this).outerHeight()}).get() );
    var maxContainerHeight = Math.max.apply(null,
      container.map(function(){
          return $(this).outerHeight()}).get() );
    
    if(maxItemHeight > maxContainerHeight)
    {
      container.css('min-height', maxContainerHeight + 'px');
    }
    else{
      container.css('min-height', maxItemHeight + 'px');
    }
    items.css('min-height', maxItemHeight + 'px');
    
  });
}

var submitSearchForm = function() {
  $("#home_page_search").on('keypress', function(element){
    if(element.which === ENTER_KEY)
    {
      $("#home-page-search-form").submit();
    }
  });  
}

$(document).on('turbolinks:load', submitSearchForm);
$(window).on('load resize orientationchange', equalItemHeight);

$(document).on('turbolinks:load', ()=>{
  // Init the carousel

  var isCarouselPlaying = true;
  var iconPlayClass = "play-icon fa-lg bi bi-play-fill";
  var iconPauseClass = "play-icon fa-lg bi bi-pause-fill";
  $('#home-page-carousel').carousel({
    interval: 1000 * 10,
    keyboard: true
  });

  $("#toggle-play").on('click', () => {
    if(isCarouselPlaying)
    {
      $('#home-page-carousel').carousel('pause');
      $("#player-icon").attr("class", iconPlayClass);
      $("#sr-carousel-toggle").html("Play Carousel");
      isCarouselPlaying = false;
    }
    else{
      $("#player-icon").attr("class", iconPauseClass);
      $("#sr-carousel-toggle").html("Pause Carousel");
      $('#home-page-carousel').carousel('cycle');
      isCarouselPlaying = true;
    }
  });


  // Bootstrap does this for the next/prev buttons, so we need to add it for the play button we added.
  $("#player-icon").on('click', (e) => {
    e.preventDefault();
  });
  
  $('#home-page-carousel').on('slide.bs.carousel', function (event) {
    const { to } = event;
    $("#carousel-change").html(`Carousel has changed to item number ${to + 1}`);
  })

});