var announcementTable;
var announcement = function() {
  const createTableQueryData = function() {
    return {
      save_state: "true",
      status: $("#status").val(),
      published_date: 
      {
        to: $("#published_date_to").val(),
        from: $("#published_date_from").val()
      },
      search: $("#search").val()
    };
  }
  
  announcementTable = $('#announcements').DataTable( {
    serverSide: true,
    processing: true,
    searching: false,
    stateSave: true,
    columnDefs: [{
      "targets": [0],
      "width": "20%"
    },
    {
      "targets": [1],
      "width": "40%"
    }],
    ajax: {
      "url": $('#announcements').data("source"),
      "type": "POST",
      "data": function ( d ) {
        return $.extend( {}, d, createTableQueryData());
      }
    }
  });

  $("#filter-button").on('click', () => {
    announcementTable.ajax.reload();
  });

  $("#reset-button").on('click', ()=> {
    $("#status").val("");
    $("#published_date_to").val("");
    $("#published_date_from").val("");
    $("#search").val("");
    announcementTable.ajax.reload();
  });
}

var allowLinksInAccordion = () =>
{
  $(".faq-content").on('click', (event)=>{
    console.log("Hey!");
    event.stopPropagation();
  });
}





$(document).on("turbolinks:load", allowLinksInAccordion);
$(document).on("turbolinks:load", announcement);
$(document).on("turbolinks:before-cache", function() {
  if ($('#announcements').length === 1) {
    return announcementTable.destroy();
  }
});