import { handleDatatableRedirect } from './utils';
require('multiple-select');

var resourceTable;
$(document).on('turbolinks:load', function(){
  resourceTable = $('#resources').DataTable( {
      serverSide: true,
      processing: true,
      searching: false,
      stateSave: true,
      columnDefs: [ {
        "targets": [5,6,10],
        "orderable": false
        },
      {
        "targets": [5,6],
        "width": "15%"
      }],
      ajax: {
        "url": "/admin/resources_data.json",
        "type": "POST",
        "data": function ( d ) {
          return $.extend( {}, d, {
            "save_state": "true",
            "zip_file_status": $('#admin_resources_zip_file_status').val(),
            "status_search": $('#admin_resources_status').val(),
            "state_search": $("#admin_resources_state").val(),
            "type_search": $("#admin_resources_organization_type").val(),
            "resource_roles": $("#admin_resource_roles").val(),
            "published_date": 
            {
              "to": $("#admin_resources_published_date_to").val(),
              "from": $("#admin_resources_published_date_from").val()
            },
            "end_date":
            {
              "to":  $("#admin_resources_end_date_to").val(),
              "from": $("#admin_resources_end_date_from").val()
            },
            "zip_file_date":{
              "to":  $("#admin_resources_zip_file_date_to").val(),
              "from": $("#admin_resources_zip_file_date_from").val()
            },
            "search": $("#admin_resources_search").val(),
          });
        },
        "error": function( e ) {
          handleDatatableRedirect(e);
        }
      }
    });
    // Filter values
    $("#filter-button").on('click', function(){
      resourceTable.ajax.reload();
    });

    // Reset all of the fields to their default value.
    $("#reset-button").on('click', function(){
      $("#admin_resources_status").val("");
      $("#admin_resources_search").val("");
      $("#admin_resources_state").val("");
      $("#admin_resources_type").val("");
      $("#admin_resources_zip_file_status").val("");
      $("#admin_resources_published_date_to").val("");
      $("#admin_resources_published_date_from").val("");
      $("#admin_resources_end_date_to").val("");
      $("#admin_resources_end_date_from").val("");
      $("#admin_resources_zip_file_date_to").val("");
      $("#admin_resources_zip_file_date_from").val("");
      $("#admin_resource_roles").multipleSelect('uncheckAll');
      $("#admin_resources_organization_type").multipleSelect('uncheckAll');
      $("#admin_resources_platform_provider").multipleSelect('uncheckAll');
      resourceTable.ajax.reload();
    });

});

var toggleSubmitButton = function(){
  $("#NDA-checkbox").on('click', function(e){
    $("#NDA-submit-btn").prop("disabled", !e.target.checked)
  });
}





// Show/hide fields based on a resourceItem's type
var toggleTypeFields = function()
{
  function toggle_fields(dropdown, label, url, video, file, fileGroup) {
    if(dropdown.value === "URL")
      {
        // Change label text
        $(label).text("URL");
        $(label).show();
        // Hide File + Video
        $(video).hide();
        $(file).hide();
        $(fileGroup).hide();
        // Show Url
        $(url).show();
        
      }
      else if(dropdown.value === "File")
      {
        // hide label text
        $(label).hide();
        // Hide URL + Video
        $(url).hide();
        $(video).hide();
        // Show file
        $(file).show();
        if($(fileGroup).attr("data-file-present") == "true")
        {
          $(fileGroup).show();
        }
      }
      else{
        // Change label text
        $(label).text("Embedded Video Player");
        $(label).show();
        // Hide url + file
        $(url).hide();
        $(file).hide();
        $(fileGroup).hide();
        // Show Video field
        $(video).show();
      }
  }
  //On Load show any present file groupings
$(".download-file-group").each(function(){
  if(this.getAttribute("data-file-present") == 'true')
  {
    $(`#${this.id}`).show();
  }
});

  // When a file is deleted confirm with user, then destroy instantly.
  $(".file-delete").on("click", function()
  { 
     if (confirm("Are you sure you want to delete this file? This action cannot be undone.") == true) {
        var id = this.getAttribute("data-id");
        var fileId = this.getAttribute("item-id");
        // If id is null, then it is from the nested form, else it has a static id.
        // Set file present to false and never show the group again
        if(id)
        {
          $(`#file_${id}`).attr("data-file-present", "false");
          $(`#file_${id}`).hide();
        }
        else{
          $("#file_group").attr("data-file-present", "false");
          $("#file_group").hide();
        }
       
        $.ajax({
          type: "POST",
          url: "/admin/destroy_item_file",
          data: {slug: fileId}
        });
     }    
  });

  // On Resource Edit init loop through each resource item to show the correct field.
  $(".item-type").each(function()
  {
    var dropdown = this;
    var nestedCount = dropdown.id
      .replace('resource_resource_items_attributes_', '')
      .replace('_type', '').replace('_item', '');
    var inputId = `resource_resource_items_attributes_${nestedCount}`;
    var label = $("label[for='" + inputId + "_file']");
    var file = `#${inputId}_file`;
    var video = `#${inputId}_embedded_video_player`;
    var url = `#${inputId}_url`;
    var fileField = `#file_${nestedCount}`
    toggle_fields(dropdown, label, url, video, file, fileField);
  });

  // On Resource create or edit toggle fields as needed.
  $(".item-type").on('change', function(event)
  {
    var dropdown = event.target;
    var nestedCount = dropdown.id
      .replace('resource_resource_items_attributes_', '')
      .replace('_type', '').replace('_item', '');
    var inputId = `resource_resource_items_attributes_${nestedCount}`;
    var label = $("label[for='" + inputId + "_file']");
    var file = `#${inputId}_file`;
    var video = `#${inputId}_embedded_video_player`;
    var url = `#${inputId}_url`;
    var fileField = `#file_${nestedCount}`
    toggle_fields(dropdown, label, url, video, file, fileField);
  });

  // Below are functions for the none-nested fields
  // /resource_items/edit

  // On resource item edit change, toggle to the correct field.
  $(".item-type").on('change', function(event)
  {
    var dropdown = event.target;
    var label = $("#label-field");
    var file = $("#file-upload");
    var video = $("#embedded-video-field");
    var url = $("#url-field");
    var fileField = '#file_group';
    toggle_fields(dropdown, label, url, video, file, fileField);
  });

  // On Resource Item edit init toggle to the correct field.
  $(".item-type").each(function() {
    var dropdown = this;
    var label = $("#label-field");
    var file = $("#file-upload");
    var video = $("#embedded-video-field");
    var url = $("#url-field");
    var fileField = '#file_group';
    toggle_fields(dropdown, label, url, video, file, fileField);
  });
  
}

// On document load, run this for init.
$(document).on('turbolinks:load', toggleTypeFields);
// On nested field insert run this again.
$(document).on('cocoon:after-insert', toggleTypeFields);
$(document).on("turbolinks:before-cache", function() {
  if ($('#resources_wrapper').length === 1) {
    return resourceTable.destroy();
  }
});

$(document).on('turbolinks:load', function()
{
  $('.multiple-select').multipleSelect(
    {
      selectAll: false
    });
});


// When coming in from the NDA we need to make sure the user is able to view the item in a new window.
// In order to do this we will create a hidden link with the target slug and click on it.
// We only want to do this with a hidden field since I don't want this method to run on every page with the param view_item.
var viewResourceItem = function(){
  var itemSlug = $("#resource-view-item").val();
  if(itemSlug && itemSlug !== "")
  {
    // Reset the resource view item.
    $("#resource-view-item").val("");
    // Create a hidden link and click it like we are doing for CSV downloads
    const hiddenLink = document.createElement("a");
    document.body.appendChild(hiddenLink);
    hiddenLink.style = "display: none";
    hiddenLink.href = "/resource_item/"+ itemSlug;
    hiddenLink.click();    
  }
}


$(document).on('turbolinks:load', viewResourceItem);
$(document).on('turbolinks:load', toggleSubmitButton);